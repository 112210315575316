import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import HeroSectionBroker from "../components/broker-sign-up-hero";
import AboutInsuranceSection from "../components/about-insurance-section";
import GetQuoteBanner from "../components/get-quote-banner";
import RelatedInsurances from "../components/realted-insurances";
import BestQuoteInsuranceSection from "../components/best-quote-insurance-section";
import FaqSection from "../components/faq-section";
import NewsletterBanner from "../components/newsletter-banner";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
// Import your section components like HeroSection, AboutSection, etc.

export const query = graphql`
	query InsurancePageById($id: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		wpInsurancePage(id: { eq: $id }) {
			seoFields {
				metaTitle
				opengraphTitle
				opengraphDescription
				metaDescription
				image {
					altText
					link
					localFile {
						childImageSharp {
							original {
								height
								width
							}
						}
					}
				}
			}
			title
			slug
			insurancePageFields {
				cardDescription
				aboutSection {
					description
					title
					features {
						title
						feature1 {
							title
							description
							icon {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						feature2 {
							description
							title
							icon {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						feature3 {
							description
							title
							icon {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						feature4 {
							description
							title
							icon {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
				catBannerSection {
					title
					description
					buttonText
				}
				catBannerSection2 {
					title
				}
				faqSection {
					title
					description
					faq1 {
						... on WpFaq {
							id
							faqFields {
								answer
								question
							}
						}
					}
					faq2 {
						... on WpFaq {
							id
							faqFields {
								answer
								question
							}
						}
					}
					faq3 {
						... on WpFaq {
							id
							faqFields {
								answer
								question
							}
						}
					}
					faq4 {
						... on WpFaq {
							id
							faqFields {
								answer
								question
							}
						}
					}
				}
				haveRelatedInsurances
				heroSection {
					buttonText
					description
					title
					image {
						altText
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
				insuranceType {
					... on WpInsuranceCategory {
						id
						title
					}
				}
				selectedForm {
					... on WpForm {
						id
						title
						slug
					}
				}
				relatedInsuranceSection {
					title
					insurance1 {
						... on WpInsurancePage {
							id
							slug
							title
							insurancePageFields {
								cardDescription
							}
						}
					}
					insurance2 {
						... on WpInsurancePage {
							id
							slug
							title
							insurancePageFields {
								cardDescription
							}
						}
					}
					insurance3 {
						... on WpInsurancePage {
							id
							slug
							title
							insurancePageFields {
								cardDescription
							}
						}
					}
				}
				theBestQuoteSection {
					buttonText
					description
					title
					image1 {
						altText
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
					image2 {
						altText
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
		}
	}
`;

const InsurancePageTemplate = ({ data }) => {
	const {
		aboutSection,
		catBannerSection,
		faqSection,
		heroSection,
		insuranceType,
		selectedForm,
		relatedInsuranceSection,
		theBestQuoteSection,
		catBannerSection2,
		haveRelatedInsurances,
	} = data.wpInsurancePage.insurancePageFields;

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Service",
		"@id": `${data.site.siteMetadata.siteUrl}/insurance/${data.wpInsurancePage.slug}`,
		image: `${data.wpInsurancePage.seoFields.image?.link}`,
		serviceType: `${data.wpInsurancePage.title}`,
		description: `${data.wpInsurancePage.seoFields.metaDescription}`,
		provider: {
			"@type": "Organization",
			name: "Quotestar",
			logo: `https://quote-star.rjmdigital.net/wp-content/uploads/2024/03/icon-new.png`,
			url: `${data.site.siteMetadata.siteUrl}`,
		},

		termsOfService:
			"https://quotestar.co/policies/terms-and-conditions-customer",
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${data.site.siteMetadata.siteUrl}`,
					id: `${data.site.siteMetadata.siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: `${data.wpInsurancePage.title}`,
				item: {
					url: `${data.site.siteMetadata.siteUrl}/${data.wpInsurancePage.slug}`,
					id: `${data.site.siteMetadata.siteUrl}/${data.wpInsurancePage.slug}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={data.wpInsurancePage.seoFields.metaTitle}
				description={data.wpInsurancePage.seoFields.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${data.site.siteMetadata.siteUrl}/${data.wpInsurancePage.slug}`,
					title: data.wpInsurancePage.seoFields.opengraphTitle,
					description: data.wpInsurancePage.seoFields.opengraphDescription,
					images: [
						{
							url: `${data.wpInsurancePage.seoFields.image?.link}`,
							width: `${data.wpInsurancePage.seoFields.image?.localFile.childImageSharp.original.width}`,
							height: `${data.wpInsurancePage.seoFields.image?.localFile.childImageSharp.original.height}`,
							alt: `${data.wpInsurancePage.seoFields.image?.altText}`,
						},
					],
				}}
			/>
			<section className="py-5 py-lg-7">
				<HeroSectionBroker
					title={heroSection?.title}
					subtitle={heroSection?.description}
					buttonText={heroSection?.buttonText}
					buttonLink={`/form/${selectedForm?.slug}`}
					image={
						heroSection?.image?.localFile?.childImageSharp?.gatsbyImageData
					}
					altText={heroSection?.image?.altText}
				/>
			</section>
			<section className="bg-med-grey py-5 py-lg-7">
				<AboutInsuranceSection
					title={aboutSection?.title}
					content={aboutSection?.description}
					featuresTitle={aboutSection?.features?.title}
					Feature1Icon={
						aboutSection?.features?.feature1?.icon?.localFile?.childImageSharp
							?.gatsbyImageData
					}
					Feature1IconAlt={aboutSection?.features?.feature1?.icon?.altText}
					Feature1title={aboutSection?.features?.feature1?.title}
					Feature1description={aboutSection?.features?.feature1?.description}
					Feature2Icon={
						aboutSection?.features?.feature2?.icon?.localFile?.childImageSharp
							?.gatsbyImageData
					}
					Feature2IconAlt={aboutSection?.features?.feature2?.icon?.altText}
					Feature2title={aboutSection?.features?.feature2?.title}
					Feature2description={aboutSection?.features?.feature2?.description}
					Feature3Icon={
						aboutSection?.features?.feature3?.icon?.localFile?.childImageSharp
							?.gatsbyImageData
					}
					Feature3IconAlt={aboutSection?.features?.feature3?.icon?.altText}
					Feature3title={aboutSection?.features?.feature3?.title}
					Feature3description={aboutSection?.features?.feature3?.description}
					Feature4Icon={
						aboutSection?.features?.feature4?.icon?.localFile?.childImageSharp
							?.gatsbyImageData
					}
					Feature4IconAlt={aboutSection?.features?.feature4?.icon?.altText}
					Feature4title={aboutSection?.features?.feature4?.title}
					Feature4description={aboutSection?.features?.feature4?.description}
				/>
			</section>
			<GetQuoteBanner
				title={catBannerSection?.title}
				description={catBannerSection?.description}
				buttonText={catBannerSection?.buttonText}
				buttonLink={`/form/${selectedForm?.slug}`}
			/>
			{haveRelatedInsurances === true && (
				<section className="bg-med-grey ">
					<RelatedInsurances
						title={relatedInsuranceSection?.title}
						insurance1={relatedInsuranceSection?.insurance1}
						insurance2={relatedInsuranceSection?.insurance2}
						insurance3={relatedInsuranceSection?.insurance3}
					/>
				</section>
			)}
			<section>
				<BestQuoteInsuranceSection
					title={theBestQuoteSection?.title}
					description={theBestQuoteSection?.description}
					buttonText={theBestQuoteSection?.buttonText}
					buttonLink={`/form/${selectedForm?.slug}`}
					imageOne={
						theBestQuoteSection?.image1?.localFile?.childImageSharp
							?.gatsbyImageData
					}
					imageTwo={
						theBestQuoteSection?.image2?.localFile?.childImageSharp
							?.gatsbyImageData
					}
					altText1={theBestQuoteSection?.image1?.altText}
					altText2={theBestQuoteSection?.image2?.altText}
				/>
			</section>
			<section className="bg-med-grey py-5 py-lg-7">
				<FaqSection
					bgColour="#F5F5F5"
					title={faqSection?.title}
					description={faqSection?.description}
					faq1Question={faqSection?.faq1?.faqFields?.question}
					faq1Answer={faqSection?.faq1?.faqFields?.answer}
					faq2Question={faqSection?.faq2?.faqFields?.question}
					faq2Answer={faqSection?.faq2?.faqFields?.answer}
					faq3Question={faqSection?.faq3?.faqFields?.question}
					faq3Answer={faqSection?.faq3?.faqFields?.answer}
					faq4Question={faqSection?.faq4?.faqFields?.question}
					faq4Answer={faqSection?.faq4?.faqFields?.answer}
				/>
			</section>
			<NewsletterBanner title={catBannerSection2?.title} />
		</Layout>
	);
};

export default InsurancePageTemplate;
