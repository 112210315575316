import React, { useState } from "react";
import {
	Container,
	Row,
	Col,
	InputGroup,
	FormControl,
	Button,
	Alert,
	Form,
	Link,
} from "react-bootstrap";
import parse from "html-react-parser";
import SafeHtmlParser from "./safe-html-parser";

const GetQuoteBanner = ({ title, description, buttonText, buttonLink }) => {
	return (
		<section
			style={{ background: "linear-gradient(#001249, #000B2D)" }}
			className="py-5 py-lg-7"
		>
			<Container className="newsletter-subscribe-container">
				<Row className="justify-content-center align-items-center">
					<Col md={8} className=" text-white">
						<SafeHtmlParser htmlContent={title} />

						<p className=" text-white mt-3">{description}</p>
					</Col>
					<Col className="text-end" md={4}>
						<Button
							size="lg"
							className=" w-100 w-md-auto mt-4 mt-md-0 gradient-button px-5 py-3 text-uppercase"
							href={buttonLink}
						>
							{buttonText}
						</Button>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default GetQuoteBanner;
